import { Button, Cookie, FeatureFlag, Http } from "@bankmonitor/bm-ui-kit";
import autobind from "autobind-decorator";
import type { ReactNode } from "react";
import * as React from "react";
import reactGa from "react-ga4";
import { Route } from "react-router-dom";
import Notifications from "@component/Notifications";
import LoginPage from "@page/auth/LoginPage";
import LostPasswordPage from "@page/auth/LostPasswordPage";
import RegistrationPage from "@page/auth/RegistrationPage";
import LoadingPage from "@page/LoadingPage";
import MaintenancePage from "@page/MaintenancePage";
import NotFoundPage from "@page/NotFoundPage";
import type { AuthenticationContextType, MFAStatus } from "@service/AuthenticationContext";
import AuthContext from "@service/AuthenticationContext";
import AuthenticationService from "@service/AuthenticationService";
import type { UserData } from "@service/AuthenticationService.type";
import { BM_API_URL, BM_VERSION, MAINTENANCE_ENABLED } from "@util/Config";
import hashCode from "@util/HashCode";
import { SentryRoutes } from "@util/Sentry";
import routes from "./routes";

const CHECK_VERSION_INTERVALL = 60_000;

type Props = {};

interface State {
	userData: UserData;
	loading: boolean;
	mfaStatus: MFAStatus;
}

export default class App extends React.Component<Props, State> {
	private timerUpdateChecker: NodeJS.Timeout;

	public constructor(props: Props) {
		super(props);

		this.state = { userData: undefined, loading: true, mfaStatus: "none" };
	}

	public async componentDidMount(): Promise<void> {
		await FeatureFlag.init(`${BM_API_URL}/api/public/config/feature/flags`);
		// CSRF token
		AuthenticationService.init().then(this.storedTokenValidator).then(this.updateChecker);

		// Facekom redirect
		const redirectFacekom = Cookie.get("bm-facekom-redirect");

		if (redirectFacekom) {
			Cookie.set("bm-facekom-redirect", undefined, 0);

			window.location.href = redirectFacekom;
		}

		document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
	}

	public componentWillUnmount(): void {
		document.removeEventListener("visibilitychange", this.handleVisibilityChange, false);

		clearInterval(this.timerUpdateChecker);
	}

	@autobind
	private handleVisibilityChange() {
		if (document.visibilityState === "hidden") {
			clearInterval(this.timerUpdateChecker);
		} else {
			this.storedTokenValidator().then(this.updateChecker);
		}
	}

	private getAuthContextValue(): AuthenticationContextType {
		const { userData, mfaStatus } = this.state;

		return {
			userData,
			mfaStatus,
			setUser: this.updateUserData,
			setMFAStatus: this.updateSMSSent,
		};
	}

	@autobind
	private storedTokenValidator(): Promise<void> {
		return AuthenticationService.getUserData()
			.then((userData: UserData) => {
				this.setState({
					userData,
					mfaStatus: AuthenticationService.hasMFA() ? "success" : "none",
					loading: false,
				});
			})
			.catch(() => {
				AuthenticationService.resetMFA();

				this.setState({
					userData: undefined,
					mfaStatus: "none",
					loading: false,
				});
			});
	}

	@autobind
	private updateChecker(): void {
		clearInterval(this.timerUpdateChecker);

		this.timerUpdateChecker = setInterval(() => {
			Http.get(`/VERSION?z=${Date.now()}`)
				.then((response) => response.text())
				.then((version: string) => {
					if (version.split(".").pop().trim() !== BM_VERSION.trim()) {
						Notifications.pushInfoMessage(
							<>
								Töltsd újra az oldalt, hogy Te is elérd a legújabb funkciókat!
								<div className="mt-2 text-end">
									<Button size="sm" outlined onClick={() => window.location.reload()}>
										Újratöltés
									</Button>
								</div>
							</>,
							"Új verzió érhető el!",
						);

						window.BM_NEED_RELOAD = true;

						clearInterval(this.timerUpdateChecker);
					}
				})
				.catch(() => {
					clearInterval(this.timerUpdateChecker);
				});
		}, CHECK_VERSION_INTERVALL);
	}

	@autobind
	private updateUserData(userData: UserData): void {
		this.setState({
			userData,
		});

		if (userData?.email) {
			reactGa.set({ userId: hashCode(userData.email) });
		}
	}

	@autobind
	private updateSMSSent(mfaStatus: MFAStatus): void {
		this.setState({
			mfaStatus,
		});
	}

	public render(): ReactNode {
		const { userData, mfaStatus, loading } = this.state;

		if (MAINTENANCE_ENABLED) {
			return <MaintenancePage />;
		}

		if (loading) {
			return <LoadingPage />;
		}

		if (!userData || mfaStatus !== "success") {
			return (
				<AuthContext.Provider value={this.getAuthContextValue()}>
					<SentryRoutes>
						<Route path="/regisztracio/" element={<RegistrationPage />} />
						<Route path="/elfelejtett-jelszo/" element={<LostPasswordPage />} />
						<Route path="*" element={<LoginPage />} />
					</SentryRoutes>
				</AuthContext.Provider>
			);
		}

		return (
			<AuthContext.Provider value={this.getAuthContextValue()}>
				<main>
					<div className="main-content">
						<SentryRoutes>
							{routes.map(({ path, element: Component }) => (
								<Route
									key={path}
									path={path}
									element={
										<React.Suspense fallback={<LoadingPage />}>
											<Component />
										</React.Suspense>
									}
								/>
							))}
							<Route path="*" element={<NotFoundPage />} />
						</SentryRoutes>
					</div>
				</main>
			</AuthContext.Provider>
		);
	}
}
